// load sass
import '../sass/common.scss'; //scssの読み込み
// modernizr // webp
import {modernizer}  from "./common/modernizr.js";
modernizer();
// load imageSwitch
import {imageSwitch} from "./common/sub_imageswtch.js";
imageSwitch();

window.addEventListener('load', () => {
  const menuObjService = document.getElementsByClassName('multiple')[0]
  const menuObjServiceContent = document.getElementsByClassName('inner-content')[0]


  if (document.getElementsByClassName('smart-menu-button')[0] !== null) {
    const smartMenuBotton = document.getElementsByClassName('smart-menu-button')[0]
    const smartMenuContent = document.getElementsByClassName('header-content')[0]
    smartMenuBotton.onclick = () => {
      if (smartMenuBotton.classList.contains('active') == false) {
        smartMenuBotton.classList.add('active')
        smartMenuContent.classList.add('active')
      } else {
        smartMenuBotton.classList.remove('active')
        smartMenuContent.classList.remove('active')
      }
    } //onclick
  // } // if have smart-menu-button.

  // if (document.getElementsByClassName('smart-link')[0] !== null) {
    const smartLinkBtn = document.getElementsByClassName('smart-link')[0]

    smartLinkBtn.onclick = () => {
      smartMenuBotton.classList.remove('active')
      smartMenuContent.classList.remove('active')
    } //onclick
  }


  // if (menuObjService !=null) {
  //   menuObjService.addEventListener('mouseover', function(event){
  //     menuObjService.classList.add('active')
  //   }) //multiple mouseover

  //   menuObjServiceContent.addEventListener('mouseleave', function(event){
  //     if (menuObjService.classList.contains('active')) {
  //       setTimeout(() => {
  //         menuObjService.classList.remove('active')
  //       }, 800);
  //     }
  //   })
  // } // if live object

}) //onload
